import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./swiperstyle.css";
// import SmallBoy from "../../assets/imgs/smallboy.jpg";
import Ashwin from "../../assets/img/achive/ashwin.jpeg";
import Deepak from "../../assets/img/achive/deepak.jpeg";
import Nivethika from "../../assets/img/achive/nivethika.jpg";
import Girlvector from "../../assets/img/achive/girls.png";
// import boyvector from "../../assets/img/achive/boys.jpg";
import Dharsini from "../../assets/img/achive/dharshini.png";


export class SamacherSwipe extends Component {

    
  render() {
    return (
      <div>

        <div>
            <h2>Our Samacher Achievers</h2>
        </div>
        <br />
         <Swiper navigation={true} modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            breakpoints={{
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
            }}
            className="mySwiper"
        >
            {/* <SwiperSlide>
              <div>
                <img alt="kRISHNAN" src={boyvector} />
                <p><b>T.KRISHNAN - <span>516/600</span></b></p>
                <p>12th - BIO MATHS</p>
              </div>              
            </SwiperSlide> */}
            <SwiperSlide>
              <div>
                <img alt="DEEPAK" src={Deepak} />
                <p><b>P.DEEPAK - <span>501/600</span></b></p>
                <p>12th - BUSINESS MATHS</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>                
              <div>
                <img alt="SRINITHI" src={Girlvector} />
                <p><b>J.SRINITHI - <span>476/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="DIVYADHARSHINI" src={Dharsini} />
                <p><b>G.DIVYADHARSHINI - <span>469/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="JAYSHREE" src={Girlvector} />
                <p><b>P.JAYASREE - <span>465/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="AJITHA" src={Girlvector} />
                <p><b>V.AJITHA - <span>453/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="NIVETHIKA" src={Nivethika} />
                <p><b>s.NIVETHIKA- <span>450/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="THIGAZHYA" src={Girlvector} />
                <p><b>THIGAZHYA - <span>444/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="Vaishali" src={Girlvector} />
                <p><b>C.VAISHALU - <span>430/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="ASWIN" src={Ashwin} />
                <p><b>ASWIN SANTHOSH KUMAR - <span>421/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="Shrimaghi" src={Girlvector} />
                <p><b>B.Shrimaghi - <span>418/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>

            
        </Swiper>
      </div>
    )
  }
}

export default SamacherSwipe;