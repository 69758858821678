import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./swiperstyle.css";
import Jeffrey from "../../assets/img/achive/Jeffrey.jpeg";
import Deepak from "../../assets/img/achive/deepak.jpeg";
import Girlvector from "../../assets/img/achive/girls.png";

export class OverallToppers extends Component {

    
  render() {
    return (
      <div>

        <div>
            <h2>Our Overall 2022-2023 Toppers</h2>
        </div>
        <br />
         <Swiper navigation={true} modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            breakpoints={{
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
            }}
            className="mySwiper"
        >
            <SwiperSlide>
              <div>
                <img alt="DEEPAK" src={Deepak} />
                <p><b>P.DEEPAK - <span>501/600</span></b></p>
                <p>12th - BUSINESS MATHS - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>                
              <div>
                <img alt="SRINITHI" src={Girlvector} />
                <p><b>J.SRINITHI - <span>476/500</span></b></p>
                <p>10th - Samacher</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="JEFFREY" src={Jeffrey} />
                <p><b>V.T.JEFFREY ROY  - <span>455/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>                       
            
        </Swiper>
      </div>
    )
  }
}

export default OverallToppers;