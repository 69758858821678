import React, { Component } from 'react'

import Boy from "../../assets/imgs/boys.png";

export class AbtUs extends Component {
  render() {
    return (
      <div>
        <div style={{    padding: "3% 0%"}} className='container-lg'>
            <div className='row align-items-center'>
                <div className='col-6 d-none d-lg-block'>
                    <img src={Boy} width="80%" alt="boy"/>
                </div>

                <div className='col-lg-6 d-none d-lg-block'>
                    <div className='align-self-start'>
                        <h2>About Us</h2>
                    </div>

                    <div className='text-start py-4'> 
                        <h3>Who are we ?</h3>
                        <p>DEVVI Academy is an educational institution which specifically 
                            provides educational guidance or assists a pupil of a school or any person to prepare for an examination for past 13 years. We are formerly known as MEGA TUITION CENTER.
                            
                        </p>
                    </div>

                    <div className='text-start'>
                        <h3>What we do?</h3>
                        <p>Coachings  for 10th,11th,12th are taken. Classes for 1st std to 12th std, polytechnic Maths classes and also competitive exam such as TNPSC,RRB, SSC Maths,
                        <b>JEE Main and NEET Exam</b> coachings are taken.  
                        </p>
                    </div>

                    {/* <div className='text-start py-4'>
                        <a href='/about' className='transparentButtons'>Learn More</a>
                    </div> */}
                </div>


                <div className='col-sm-12 d-block d-lg-none d-sm-block'>
                    <div className='align-self-start'>
                        <h2>About Us</h2>
                    </div>

                    <br />

                    <div className='text-center'>
                        <img src={Boy} width="80%" alt="boy"/>
                    </div>

                    <div className='text-center py-4'> 
                    <h3>Who are we ?</h3>
                        <p>DEVVI Academy is an educational institution which specifically 
                            provides educational guidance or assists a pupil of a school or any person to prepare for an examination for past 13 years. We are formerly known as MEGA TUITION CENTER.
                            
                        </p>
                    </div>

                    <div className='text-center'>
                    <h3>What we do ?</h3>
                        <p>Coachings  for 10th,11th,12th are taken. Classes for 1st std to 12th std, Polytechnic Maths classes and also Competitive exam such as TNPSC,RRB, SSC Maths,
                           <b>JEE Main and NEET Exam </b>coachings are taken.  
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default AbtUs;