import React, { Component } from 'react';
import ELango from "../../assets/img/elango.jpeg";
import Bala from "../../assets/img/bala.jpeg";
import Vinoth from "../../assets/img/vinoth.jpeg";
import "./support.css";
// import boyvector from "../../assets/img/achive/boys.jpg";

export class Supporter extends Component {
  render() {
    return (
      <div>
        <h2 classname="">Our Supporters</h2>
        <div style={{ padding: "3% 0%"}} className='container-lg'>
            <div className='d-flex flex-wrap justify-content-evenly'>
                <div>
                  <div>
                    <img className='spimg' style={{marginBottom: "1rem"}} src={Vinoth} alt="Vinothkumar"/>
                    <h4 className='sptitle'>Mr.P.Vinoth Kumar.,B.A,(Karathe Black Belt)</h4>
                    {/* <p className='textSize'>A teacher also called a school teacher called a school teacher</p> */}
                  </div>
                </div>
                <div>
                  <div>
                    <img className='spimg'  style={{marginBottom: "1rem"}} src={ELango} alt="Elangomani"/>
                    <h4 className='sptitle'>Er. M.Elangomani, B.E.,D.C.E.,M.B.A.,</h4>
                    {/* <p className='textSize'>A teacher also called a school teacher called a school teacher</p> */}
                  </div>
                </div>
                <div>
                  <div>
                    <img className='spimg'  style={{marginBottom: "1.8rem"}} src={Bala} alt="Balachandar"/>
                    <h4 className='sptitle'>Kavingar Dr.Balachandar.,B.COM.,M.A.,M.A.,M.A.,M.Sc.,M.Phil.,P.G.D.C.A. D.A.,</h4>
                    {/* <p className='textSize'>A teacher also called a school teacher called a school teacher</p> */}
                  </div>
                </div>
                
            </div>
        </div>
      </div>
    )
  }
}

export default Supporter;