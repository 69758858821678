import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "../gallerypg/swiperstyle.css";

export class Youtube extends Component {
    
    clicked(){
        window.location.href = "https://www.youtube.com/@deviteachersachin335";
    }
  render() {
    return (
      <div>
        <div>
            <h2>Our Channel</h2>
        </div>
        <br />
         <Swiper navigation={true} modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            breakpoints={{
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
            }}
            className="mySwiper"
        >
            
            <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/EqbAqIcJ3gI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/l_HssXSHwrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/t0LAFbnfU2g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/UUPb_hQcDxg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
                <div   target="_blank" onClick={(event) => {event.preventDefault(); window.open("https://www.youtube.com/@deviteachersachin335");}}className='morevideos'>More videos</div>
            </SwiperSlide>
        </Swiper>
      </div>
    )
  }
}

export default Youtube;