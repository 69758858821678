import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./swiperstyle.css";
// import SmallBoy from "../../assets/imgs/smallboy.jpg";
import Arun from "../../assets/img/achive/aruneswar.jpeg";
import Harini from "../../assets/img/achive/harini.jpeg";
import Jeffrey from "../../assets/img/achive/Jeffrey.jpeg";
import Krish from "../../assets/img/achive/krishanan.jpeg";
// import Girlvector from "../../assets/img/achive/girls.png";
import Sathya from "../../assets/img/achive/sathya.png";


export class CBSESwiperView extends Component {

    
  render() {
    return (
      <div>

        <div>
            <h2>Our CBSE Achievers</h2>
        </div>
        <br />
         <Swiper navigation={true} modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            breakpoints={{
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
            }}
            className="mySwiper"
        >
            <SwiperSlide>
              <div>
                <img alt="JEFFREY" src={Jeffrey} />
                <p><b>V.T.JEFFREY ROY  - <span>455/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="HARINI" src={Harini} />
                <p><b>N.S.HARINI - <span>454/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="KRISHNAN" src={Krish} />
                <p><b>T.KRISHNAN- <span>412/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="SHATHYA" src={Sathya} />
                <p><b>V.S.SHATHYA PREYA - <span>403/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img alt="Arun" src={Arun} />
                <p><b>S.ARUNESUWARAR - <span>402/500</span></b></p>
                <p>10th - CBSE</p>
              </div>              
            </SwiperSlide>
        </Swiper>
      </div>
    )
  }
}

export default CBSESwiperView;