import React, { Component } from 'react'
import "./Footer.css"

import Logo from "../../assets/imgs/logoBg.png";
import mobile from "../../assets/imgs/telephone.png";
import mail from "../../assets/imgs/new.png";

export class Footer extends Component {
  render() {
    return (
      <div>

        <div style={{padding: "3% 0%"}}>
            <div className='d-flex justify-content-evenly'>
                <div>   
                    <h2>Contact Us</h2>                    
                </div>                
            </div>

            <div className='d-flex flex-wrap align-items-center justify-content-evenly'>   
                <div className='mapDivTag'>
                  <iframe title='location of center'
                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.85485534061232!2d78.69573551294141!3d10.912081729528108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf7fed177cc2b%3A0x4c67c51906a97415!2sDevviAcademy!5e0!3m2!1sen!2sin!4v1682349774486!5m2!1sen!2sin" 
                   width="100%" 
                   height="100%" 
                   allowfullscreen="" 
                   loading="lazy" 
                   referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </div>
                
                <div style={{marginTop:"1rem"}}>
                    <h4 style={{textAlign:"left"}}>Address</h4>
                    <p>56, South erimachine street, Mannachanallur, 
                       Trichy -621005.</p>
                    <p className='fotmob'><img alt="mob" src={mobile}/>+91 73736 60330</p>
                    <p className='fotmob'><img alt="mail" src={mail}/><u>deviacademymannai@gmail.com</u></p>
                </div>
                <div style={{marginTop:"0rem",marginLeft:"0.5rem"}}> 
                    <img src={Logo} style={{padding:"20px"}} alt="logo"/>
                </div>
            </div>

        </div>
        <hr/>
          <div className="cpufooter">&copy;<span id="year"> </span><span> Devvi Academy. All rights reserved.</span></div>
        <hr style={{borderColor:"transparent"}}/>
      </div>
    )
  }
}

export default Footer