import React, { Component } from 'react';
import Teach from "../../assets/imgs/teachers.jpg";
import Envi from "../../assets/imgs/environ.jpg";
import Materi from "../../assets/imgs/material.jpg";
import "./Features.css";
export class Features extends Component {
  render() {
    return (
      <div>
        <div style={{    padding: "3% 0%"}} className='container-lg'>
            <div className='d-flex flex-wrap justify-content-evenly'>
                <div>
                  <div>
                    <img style={{marginBottom: "1rem"}} src={Teach} alt="laptop"/>
                    <h4>Expert Teachers</h4>
                    <p className='textSize'>Teaching is the profession that teaches all the other professions</p>
                  </div>
                </div>
                <div>
                  <div>
                    <img style={{marginBottom: "1.8rem"}} src={Materi} alt="laptop"/>
                    <h4>Expertised Materials</h4>
                    <p className='textSize'>Our Materials are the tools of the expert</p>
                  </div>
                </div>
                <div>
                  <div>
                    <img style={{marginBottom: "1rem"}} src={Envi} alt="laptop"/>
                    <h4>Friendly Eco-System</h4>
                    <p className='textSize'>Every student feels valued, supported, and encouraged to reach their full potential</p>
                  </div>
                </div>
            </div>
        </div>

      </div>
    )
  }
}

export default Features