import React, { Component } from 'react'
import ReviewCard from './ReviewCard.component'
import "./Review.css";
import ReviewCard1 from './Reviewcard1.component';
import ReviewCard3 from './reviewCard';
export class Review extends Component {
  render() {
    return (
      <div style={{    padding: "3% 0%"}}>
        <div className='container-lg'>
            <div>
                <h2>Student Review</h2>
            </div>

            <div className='d-flex flex-wrap justify-content-around py-5'>
                <ReviewCard1/>
                <ReviewCard/>
                <ReviewCard3></ReviewCard3>
            </div>

            {/* <div className='pb-5'>
                <div className='transparentButtons'>More Reviews</div>
            </div> */}
        </div>
      </div>
    )
  }
}

export default Review