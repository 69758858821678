import React, { Component } from 'react';
import Footer from '../Footer/Footer.component';
// import Header from '../header/header';
import Supporter from '../supporter/supporter';
import Youtube from '../youtube/youtube';
import AbtUs from '../AboutUs/abtus.component';
import { Link } from "react-router-dom";
import Logo from "../../assets/imgs/logoBg.png";
import "../CustomBanner/CustomBanner.style.css";
import Founder from '../AboutUs/founder';
import Notes from '../AboutUs/notes';


export class Aboutpg extends Component {
  render() {
    return (
      <div>
        <div className='container-lg'>
          <div className='row align-items-center'>
            {/* Logo */}
            <div className='col text-start'><img width="250" src={Logo} alt="logo"/></div>

            {/* Menu */}
            <div className='myclass col d-flex justify-content-evenly'>
                
                <Link to="/home" >Home</Link> |{" "}
                <Link to="/galary">Gallery</Link> |{" "}
                <Link to="/about" style={{textDecorationLine:"underline",color:"#b20f49",fontWeight:"600"}}>About Us</Link> |{" "}
            </div>
          </div>
        </div> 
        <hr></hr>
        <AbtUs />
        <hr></hr>
        <Founder/>
        <hr></hr>
        <Notes/>
        <hr></hr>
        <Supporter/>
        <hr></hr>
        <Youtube/>
        <hr></hr>
        <Footer />        
      </div>
    )
  }
}

export default Aboutpg;