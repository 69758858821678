import React, { Component } from 'react'
import Footer from '../Footer/Footer.component';
// import Header from '../header/header';
import { PhotoView } from './photosview';
import CBSESwiperView from './swiperView';
import SamacherSwipe from './samacher';
import { Link } from "react-router-dom";
import Logo from "../../assets/imgs/logoBg.png";
import "../CustomBanner/CustomBanner.style.css";


export class Galarypg extends Component {
  render() {
    return (
      <div>
        <div className='container-lg'>
          <div className='row align-items-center'>
            {/* Logo */}
            <div className='col text-start'><img width="250" src={Logo} alt="logo"/></div>

            {/* Menu */}
            <div className='myclass col d-flex justify-content-evenly'>
                
                <Link to="/home" >Home</Link> |{" "}
                <Link to="/galary" style={{textDecorationLine:"underline",color:"#b20f49",fontWeight:"600"}}>Gallery</Link> |{" "}
                <Link to="/about">About Us</Link> |{" "}
            </div>
          </div>
        </div>       
        <hr></hr>
        <SamacherSwipe/>
        <hr/>
        <CBSESwiperView />
        <hr></hr>   
        <PhotoView/>
        <hr/>
        <Footer />
      </div>
    )
  }
}

export default Galarypg;