export const Images=[
    {
    src: require("../../assets/img/d1.jpeg")
    },
    {
    src: require("../../assets/img/d2.jpeg")
    },
    {
    src: require("../../assets/img/d3.jpeg")
    },
    {
    src: require("../../assets/img/d4.jpeg")
    },
    {
    src: require("../../assets/img/d5.jpeg")
    },
    {
    src: require("../../assets/img/d6.jpeg")
    },
    {
    src: require("../../assets/img/d7.jpeg")
    },
    {
    src: require("../../assets/img/d8.jpeg")
    },
    {
    src: require("../../assets/img/d9.jpeg")
    },
    {
    src: require("../../assets/img/d10.jpeg")
    },
    {
    src: require("../../assets/img/d11.jpeg")
    },
    {
    src: require("../../assets/img/d12.jpeg")
    },
    {
    src: require("../../assets/img/d13.jpeg")
    },
    {
    src: require("../../assets/img/d14.jpeg")
    },
    {
    src: require("../../assets/img/d15.jpeg")
    },
    {
    src: require("../../assets/img/d16.jpeg")
    },
    {
    src: require("../../assets/img/d17.jpeg")
    },
    {
    src: require("../../assets/img/d18.jpeg")
    },
    {
    src: require("../../assets/img/d19.jpeg")
    },
    {
    src: require("../../assets/img/d20.jpeg")
    },
    {
    src: require("../../assets/img/d21.jpeg")
    },
    {
    src: require("../../assets/img/d22.jpeg")
    },    
    {
    src: require("../../assets/img/d25.jpeg")
    },
    {
    src: require("../../assets/img/d26.jpeg")
    },
    {
    src: require("../../assets/img/d27.jpeg")
    },
    {
    src: require("../../assets/img/d28.jpeg")
    },
    {
    src: require("../../assets/img/d29.jpeg")
    },
    {
    src: require("../../assets/img/d30.jpeg")
    },
    {
    src: require("../../assets/img/d31.jpeg")
    },
    {
    src: require("../../assets/img/d32.jpeg")
    },
    {
    src: require("../../assets/img/d33.jpeg")
    },    
    {
    src: require("../../assets/img/im1.jpg")
    },
    {
    src: require("../../assets/img/im2.jpg")
    },
    {
    src: require("../../assets/img/im3.jpg")
    },
    {
    src: require("../../assets/img/im4.jpg")
    },
    {
    src: require("../../assets/img/im5.jpg")
    },
    {
    src: require("../../assets/img/im6.jpg")
    },
    {
    src: require("../../assets/img/im7.jpg")
    },
    {
    src: require("../../assets/img/im8.jpg")
    },
    {
    src: require("../../assets/img/im9.jpg")
    },
    {
    src: require("../../assets/img/im10.jpg")
    },
    {
    src: require("../../assets/img/im11.jpg")
    },
    {
    src: require("../../assets/img/im12.jpg")
    },
    {
    src: require("../../assets/img/im13.jpg")
    },
    {
    src: require("../../assets/img/im14.jpg")
    },
    {
    src: require("../../assets/img/im15.jpg")
    },
    {
    src: require("../../assets/img/im16.jpg")
    },
    {
    src: require("../../assets/img/im17.jpg")
    },
    {
    src: require("../../assets/img/im18.jpg")
    },
    {
    src: require("../../assets/img/im19.jpg")
    },
    {
    src: require("../../assets/img/im20.jpg")
    },
    {
    src: require("../../assets/img/im21.jpg")
    },
    {
    src: require("../../assets/img/im22.jpg")
    },
    {
    src: require("../../assets/img/im23.jpg")
    },
    {
    src: require("../../assets/img/im24.jpg")
    },
    {
    src: require("../../assets/img/im25.jpg")
    },
    {
    src: require("../../assets/img/im26.jpg")
    },
    ];