import React, { Component } from 'react'
import CustomBanner from '../CustomBanner/CustomBanner.component';
import Features from '../Features/Features.component';
import AboutUs from '../AboutUs/AboutUs.component';
import Gallery from '../Gallery/Gallery.component';
// import Courses from '../Courses/Courses.component';
import Review from '../Review/Review.component';
import Footer from '../Footer/Footer.component';
// import Header from '../header/header';
import OverallToppers from '../gallerypg/toppers';
import TextScroller from '../header/textmover';

import { Link } from "react-router-dom";
import Logo from "../../assets/imgs/logoBg.png";
import "../CustomBanner/CustomBanner.style.css";



export class Home extends Component {
  render() {
    return (
      <div>
        <div className='container-lg'>
          <div className='row align-items-center'>
            {/* Logo */}
            <div className='col text-start'><img width="250" src={Logo} alt="logo"/></div>

            {/* Menu */}
            <div className='myclass col d-flex justify-content-evenly'>
                
                <Link to="/home" style={{textDecorationLine:"underline",color:"#b20f49",fontWeight:"600"}}>Home</Link> |{" "}
                <Link to="/galary">Gallery</Link> |{" "}
                <Link to="/about">About Us</Link> |{" "}
            </div>
          </div>
        </div> 
        <hr></hr>
        
        <TextScroller text="Coachings available for all subjects in samacher,CBSE and ICSE(Maths).Also coachings available for JEE Main, NEET ,TNPSC and other competitive exams." />
        <CustomBanner />
        <hr></hr>
        <Features />
        <hr></hr>
        <AboutUs />
        <hr></hr>
        <Gallery />
        <hr></hr>
        <OverallToppers />
        <hr/>
        <Review />
        <hr></hr>
        <Footer />
      </div>
    )
  }
}

export default Home;