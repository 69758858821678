import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import React, { useState,useEffect } from 'react';
import './App.css';
import Aboutpg from "./component/aboutpg/aboutpg";
import Galarypg from "./component/gallerypg/galarypg";
import Home from './component/home/home';
import SplashScreen from "./component/splash/splashscreen";

function App() {
  const [showSplash, setShowSplash] = useState(true);
  
  useEffect(()=>{
  const timeout = setTimeout(()=> setShowSplash(false),3000)
  return () => clearTimeout(timeout)
  }, [])
  


    if (showSplash){
       return (
         <SplashScreen/>
       )
     }else{
      return (
        <div className="App">
          
          <Router>
            <div className="container">
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/about" element={<Aboutpg />} />
                <Route path="/galary" element={<Galarypg />} />
            </Routes>
            </div>
          
          </Router>      
        </div>
      );
   }
}

export default App;
