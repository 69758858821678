import React, { Component } from 'react'

import Avatar from "../../assets/imgs/avatar.png";

export class Notes extends Component {
  render() {
    return (
      <div>
        <div style={{    padding: "3% 0%"}} className='container-lg'>
            <div className='row align-items-center'>
                <div className='col-6 d-none d-lg-block'>
                    <img src={Avatar} width="100%" alt="boy"/>
                </div>

                <div className='col-lg-6 d-none d-lg-block'>
                    <div className='align-self-start'>
                        <h2>Notes from our Founder</h2>
                    </div>

                    <div className='text-start py-4'> 
                        <h3>Dear Students,</h3>
                        <p><span style={{marginLeft:"1rem"}}>My</span> wish for you is that you see the light in the this world,in yourself and in others. Believe in yourself, for you are stronger than you know.I believe in you.
                        </p>
                        <p><span style={{marginLeft:"1rem"}}>Try</span> hard, but know your limits, you are a unique, special, an amazing person.There will never be another you. I appreciate all of you. To speed up the process of learning advanced mathematics, listen carefully and do more practise for every problem.</p>
                        <p><span style={{marginLeft:"1rem"}}>As</span> a learner,you have to do practice.It will become to learn math quickly.Review your mistake in each steps. You need to understand the concepts.Identify the logic behind the problems.</p>
                        <p>All the best my dears.</p>
                    </div>      

                </div>


                <div className='col-sm-12 d-block d-lg-none d-sm-block'>
                    <div className='align-self-start'>
                        <h2>Notes from our Founder</h2>
                    </div>

                    <br />

                    <div className='text-center'>
                        <img src={Avatar} width="100%" alt="boy"/>
                    </div>

                    <div className='text-start py-4'> 
                        <h3>Dear Students,</h3>
                        <p><span style={{marginLeft:"1rem"}}>My</span> wish for you is that you see the light in the this world,in yourself and in others. Believe in yourself, for you are stronger than you know.I believe in you.
                        </p>
                        <p><span style={{marginLeft:"1rem"}}>Try</span> hard, but know your limits, you are a unique, special, an amazing person.There will never be another you. I appreciate all of you. To speed up the process of learning advanced mathematics, listen carefully and do more practise for every problem.</p>
                        <p><span style={{marginLeft:"1rem"}}>As</span> a learner,you have to do practice.It will become to learn math quickly.Review your mistake in each steps. You need to understand the concepts.Identify the logic behind the problems.</p>
                        <p>All the best my dears.</p>
                    </div>   
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default Notes;