import React, { Component } from 'react'
import "./Gallery.css"
import Boy from "../../assets/imgs/sboys.png";

export class Gallery extends Component {
  render() {
    return (
      <div>
        <div style={{    padding: "3% 0%"}} className='container-lg'>
            <div className='row align-items-center'>

                <div   style={{paddingLeft: "3rem"}}  className='col-lg-6 d-none d-lg-block'>
                    <div className='align-self-start'>
                        <h2>Gallery</h2>
                    </div>

                    <div className='text-start py-4'> 
                        <h3>Success Stories</h3>
                    </div>

                    <div className='text-start'>
                        <h3>From Our Students</h3><br />
                        <p>Tuition centre means an educational institution which specifically 
                            provides educational guidance or assists a pupil of a school or
                            educational institution or any person to prepare for an examination.
                        </p>
                    </div>

                    <div className='text-start py-4'>
                        <a href="/galary" className='transparentButtons'>See More</a>
                    </div>

                    
                </div>

                <div className='col-6 d-none d-lg-block'>
                    <img src={Boy} width="80%" alt="boy"/>
                </div>

                <div className='col-sm-12 d-block d-lg-none d-sm-block'>
                    <div className='align-self-start'>
                        <h2>Gallery</h2>
                    </div>

                    
                    <div className='text-center'>
                        <img src={Boy} width="80%" alt="boy"/>
                    </div>

                    <div className='text-center py-4'> 
                        <h3>Success Stories</h3>
                    </div>

                    <div className='text-center'>
                        <h3>From Our Students</h3><br />
                        <p>Tuition centre means an educational institution which specifically 
                            provides educational guidance or assists a pupil of a school or
                            educational institution or any person to prepare for an examination.
                        </p>
                    </div>

                    <div className='text-center py-4'>
                        <a href="/galary" className='transparentButtons'>See More</a>
                    </div>

                </div>

                
            </div>
        </div>
      </div>
    )
  }
}

export default Gallery