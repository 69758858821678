import { Player, Controls } from '@lottiefiles/react-lottie-player';
import {Animated} from "react-animated-css";

import splashgif from "../../assets/gif/boyfly.gif";

const SplashScreen = () => {

    return (
        
        <div className='container-lg'>
            <div className='row align-items-center' style={{marginTop:"15%",marginLeft:"30%"}}>
                <div className='col-lg-6 d-none d-lg-block'>
                    <div className="justify-content-center" style={{margin: "auto",
                            display:"flex",
                            paddingTop:"2rem",
                            fontSize: "2rem"}}>
                        <Animated animationIn="headShake" animationOut="headShake" animationInDuration={5000} animationOutDuration={5000} isVisible={true}>
                            <div>
                            <div style={{color:"#126a6d "}}>Welcome You all</div>
                            <div style={{color:"#b20f49 "}}><b>Devvi Academy</b></div>
                            <div style={{color:"#126a6d"}}> Mannachanallur</div>                                   
                            </div>
                        </Animated>
                        </div>

                        <div style={{display:"flex",justifyContent:"center"}}>
                        
                            <img src={splashgif}  alt="Loading..."/>
                                
                        </div>
                    </div>
                </div>
        

            <div className='col-sm-12 d-block d-lg-none d-sm-block' style={{marginTop:"25%",marginLeft:"10%"}}>  
          
                <div style={{
                    marginLeft: "10%",                
                    paddingTop:"1rem",
                    display:"flex",
                    fontSize: "2rem"}}>
                <Animated animationIn="headShake" animationOut="headShake" animationInDuration={5000} animationOutDuration={5000} isVisible={true}>
                        <div>
                            <div style={{color:"#126a6d "}}>Welcome You all</div>
                            <div style={{color:"#b20f49 "}}><b>Devvi Academy</b></div>
                            <div style={{color:"#126a6d"}}> Mannachanallur</div>                               
                        </div>
                </Animated>
                </div>

                <div style={{display:"flex",justifyContent:"center"}}>
                
                     <img src={splashgif}  alt="Loading..."/>
                
                </div>
            </div>
      </div>

    );
  };

export default SplashScreen;