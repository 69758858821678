import React, { Component } from 'react'

import Found from "../../assets/imgs/founder.jpg";

export class Founder extends Component {
  render() {
    return (
      <div>
        <div style={{    padding: "3% 0%"}} className='container-lg'>
            <div className='row align-items-center'>
               
                

                <div className='col-lg-6 d-none d-lg-block'>
                    <div className='align-self-start'>
                        <h2>Our Founder and Coordinator</h2>
                    </div>

                    <div className='text-center py-4'> 
                    <h3>Ms. M. Devi, M.Sc., M.Sc., B.Ed., M.Phil., The founder and coordinator of Devvi Academy,Mannachanallur,Trichy.</h3>
                        <p> Our founder is a positive and energetic leader and tutor. She received her Master of Philosophy and Post-Graduation in Mathematics from shrimathi Indira Gandhi college and Bachelor of Education from M.A.M College of Education with a great aspiration to serve the students society.She started her academic service in the year 2010.
                        </p>
                    </div>                                    
                </div>

                <div className='col-6 d-none d-lg-block'>
                    <img src={Found} width="80%" alt="boy"/>
                </div>


                <div className='col-sm-12 d-block d-lg-none d-sm-block'>
                    <div className='align-self-start'>
                        <h2>Our Founder and Coordinator</h2>
                    </div>

                    <br />

                    <div className='text-center'>
                        <img src={Found} width="80%" alt="boy"/>
                    </div>

                    <div className='text-center py-4'> 
                    <h3>Ms. M. Devi, M.Sc.,M.Sc., B.Ed., M.Phil., The founder and coordinator of Devvi Academy,Mannachanallur,Trichy.</h3>
                        <p> Our founder is a positive and energetic leader and tutor. She received her Master of Philosophy and Post-Graduation in Mathematics from shrimathi Indira Gandhi college and Bachelor of Education from M.A.M College of Education with a great aspiration to serve the students society.She started her academic service in the year 2010.
                        </p>
                    </div>

                    
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default Founder;