import React, { Component } from 'react'
import ResponsiveGallery from 'react-responsive-gallery';
import { Images } from './imageUrl';

export class PhotoView extends Component {
    
  render() {
    return (
      <div>
        <ResponsiveGallery 
        useLightBox={true} 
        colsPadding={{xs:4,s: 4,m: 4,l: 4,xl: 5,xxl:6}} 
        imagesPaddingBottom={{xs:4,s: 4,m: 4,l: 4,xl: 5,xxl:6}}
        numOfImagesPerRow={{xs: 1,s: 2,m: 3,l: 3,xl: 3, xxl:4}}
        images={Images}/>
      </div>
    )
  }
}

export default PhotoView;