import React, { Component } from 'react';
import Banner2 from "../../assets/imgs/girl 2.png";
import "./CustomBanner.style.css";

export class CustomBanner extends Component {
  render() {
    return (
      <div className="padding10">

        

        <div className="banner container">
            <div className="bannerLeft row">
                <div className="bannerContent col-lg-3 col-sm-3 text-start ">
                    <div></div>
                    
                    <div className='d-flex flex-column mb-3 p-5 bannerText'>
                      <div><h1>Welcome</h1></div>
                      <div><h1>To Devvi</h1></div>
                      <div><h1>Academy</h1></div>
                    </div>
                    <div className='p-5 text-center'><a className='transparentButtons' href="/about" type="button">Learn more</a></div>
                </div>

                <div className='col-9 d-none d-lg-block'>
                    <img src={Banner2} alt=""/>
                </div>
            </div>

            {/* <div>
                <p>circle 1</p>
                <p>circle 2</p>
                <p>circle 3</p>
            </div> */}
        </div>

      </div>
    )
  }
}

export default CustomBanner