import React, { Component } from 'react'
import "./Review.css"
// import profile from "../../assets/imgs/profile.svg";
// import boyvect from "../../assets/img/achive/boys.jpg";
import girlvect from "../../assets/img/achive/girls.png";
import StarRatings from 'react-star-ratings';

export class ReviewCard1 extends Component {
  render() {
    return (
      <div className='py-2'>
        <div className='profiler text-start'>            
            <div className='profile d-inline-flex '>
                <div className=''><img className='rounded-circle' width="100px" src={girlvect} alt="img"/></div>
                <div className='text-start  align-self-center'>
                    <div><p className='mb-0 px-2'>&nbsp;&nbsp;Srinithi J </p></div>
                    <div>
                    &nbsp;&nbsp;<StarRatings
                        rating={4.89}
                        starDimension="20px"
                        starSpacing="4px"
                        starRatedColor="blue"
                        numberOfStars={5}
                      />
                    </div>
                </div>
                
            </div>
            
            <div className='profile-content py-5 '>
                <p>
                Thanks to Devvi academy, I am secured a 95 mark
                in the Maths subjects. The lessons made me
                more comfortable and being happy to clarify the doubts.
                This helped to understanding the concepts deeply.Finally, I am so proud
                and happy! In my view, this is special place for mathematics coaching.
                </p>
            </div>
        </div>
      </div>
    )
  }
}

export default ReviewCard1;