import React, { Component } from 'react'
import "./Review.css"
// import profile from "../../assets/imgs/profile.svg";
import boyvect from "../../assets/img/achive/boys.jpg";
// import girlvect from "../../assets/img/achive/girls.png";
import StarRatings from 'react-star-ratings';

export class ReviewCard3 extends Component {
  render() {
    return (
      <div className='py-2'>
        <div className='profiler text-start'>            
            <div className='profile d-inline-flex '>
                <div className=''><img className='rounded-circle' width="100px" src={boyvect} alt="img"/></div>
                <div className='text-start  align-self-center'>
                    <div><p className='mb-0 px-2'>&nbsp;&nbsp;P.Deepak</p></div>
                    <div>
                    &nbsp;&nbsp;<StarRatings
                        rating={4.8}
                        starDimension="20px"
                        starSpacing="4px"
                        starRatedColor="blue"
                        numberOfStars={5}
                      />
                    </div>
                </div>
                
            </div>
            
            <div className='profile-content py-5 '>
                <p>
                    I studied at Devvi academy and got good score in my 12th standard.I hope my review about the academy will help others to find this great place for coaching in our region.Here I found so many specialities, among that very liked one was doubt clarification. I asked doubt many times and all doubts would be gently clarified by our tutors.Thanks to our Devvi academy.
                
                </p>
            </div>
        </div>
      </div>
    )
  }
}

export default ReviewCard3;